/* =================== MENU =================== */
jQuery(document).ready(function($){
  function toggleMenu() {
    if (isMobileMaior(759)) {
    	var scrollTop = $(window).scrollTop();
      if (scrollTop > 100) {
        $('.header').addClass('active');
      } else {
        $('.header').removeClass('active');
      }
    }
  }
  toggleMenu();
  $(window).on('scroll', debounce(function(){
    toggleMenu();        
  }, 10));
});
