/* =================== SLIDE HOME =================== */
jQuery(document).ready(function($){
  $('.slide-home')
  .on('init', function() {
   $('.slide-content').show();
 })
  .slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    adaptiveHeight: true
  })
  .on('beforeChange', function() {
   $('.slide-content').hide();
 })
  .on('afterChange', function() {
    $('.slide-content').show();
  });
});
